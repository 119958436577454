import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImg from "gatsby-background-image"
import styled from "@emotion/styled"
import {
  mq,
  ShowBeforeMedium,
  HideBeforeMedium,
  ShowBeforeLarge,
  HideBeforeLarge,
} from "../../helpers"
import Page from "../../page"
import PageTitle, { PageTitleParagraph } from "../../page-title"
import Container from "../../container"
import Text from "../../text"
import Link, { ExternalLinkLikeButton } from "../../link"
import Button from "../../button"
import Quotes from "./Quotes"
import pigImg from "./images/pig-bank.svg"
import coffeeImg from "./images/coffee-labor.svg"
import helmetImg from "./images/helmet.svg"
import colorPaletteImg from "./images/color-palette.svg"

const Heading = styled.div`
  ${mq({
    position: "relative",
    paddingBottom: ["59px", "200px"],
    marginLeft: [null, null, "37px"],
  })}
`
const Background = styled(BackgroundImg)`
  ${mq({
    position: "absolute !important",
    top: [0, null, null, "-100px"],
    left: 0,
    width: "100%",
    minHeight: ["300px", "485px", "549px", "756px"],
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    paddingBottom: ["59px", "200px"],
    ":before, :after": {
      backgroundPosition: ["top center !important", "top right !important"],
    },
  })}
`

const HeadingBox = styled.div`
  ${mq({
    position: "relative",
    paddingTop: ["260px", "50px"],
    maxWidth: [null, "282px", null, "390px"],
  })}
`

const HeadingText = styled(Text)`
  ${mq({
    fontSize: ["15px", null, null, "18px"],
    lineHeight: 1.47,
    marginTop: ["20px", null, null, "30px"],
  })}
`

const SunsetBox = styled.div`
  padding: 12px;
  background-color: #4680FE;
  font-weight: 700;
  color: #FFFFFF;
  margin-top: 22px;
`

const HeadingButton = styled(ExternalLinkLikeButton)`
  ${mq({
    marginTop: ["25px", "35px", "25px", "45px"],
  })}
`

const FeatureList = styled.div`
  ${mq({
    backgroundColor: "#ffffff",
    paddingTop: ["75px", "85px", null, "135px"],
  })}
`

const Feature = styled.section`
  ${mq({
    display: "flex",
    flexDirection: ["column", "row"],
    alignItems: "center",
    borderBottom: ["solid 1px #f6f7fb", "none"],
    paddingTop: ["56px", "68px"],
    paddingBottom: ["56px", "68px"],
    ":first-of-type": {
      paddingTop: 0,
    },
    ":last-of-type": {
      paddingBottom: 0,
      borderBottom: "none",
    },
  })}
`

const FeatureBox = styled.div`
  ${({ left }) =>
    mq({
      marginLeft: left ? null : [null, "39px", "85px", "68px"],
      marginRight: left ? [null, "39px", "85px", "68px"] : null,
      width: [null, "322px"],
    })}
`

const FeatureTitle = styled.h2`
  ${mq({
    fontFamily: "Bitter, serif",
    fontSize: "24px",
    lineHeight: 1.29,
    fontWeight: "normal",
    color: "#5f5f5f",
    margin: [0, null, "20px 0 0 0"],
  })}
`

const FeatureImage = styled(Img)`
  ${({ right }) =>
    mq({
      marginTop: ["30px", 0],
      boxShadow: right
        ? [null, "-8px 6px 16px 0 rgba(70, 128, 254, 0.15)"]
        : [null, "8px 6px 16px 0 rgba(70, 128, 254, 0.15)"],
    })}
`

const FeatureText = styled(Text)`
  ${mq({
    fontSize: "15px",
    lineHeight: 1.43,
    marginTop: ["45px", "25px"],
  })}
`

const SocialBox = styled.section`
  ${mq({
    backgroundColor: "#ffffff",
    paddingTop: ["113px", "165px"],
    paddingBottom: ["65px", "85px"],
  })}
`

const SocialBoxContainer = styled(Container)`
  ${mq({
    display: "flex",
    flexDirection: ["column", "row"],
    alignItems: "center",
  })}
`

const SocialBoxItem = styled.div`
  ${mq({
    flex: 1,
    margin: [null, "0 20px", "0 54px"],
    ":first-of-type": {
      marginLeft: [null, 0],
    },
    ":last-of-type": {
      marginRight: [null, 0],
    },
  })}
`

const ToolsImage = styled(Img)`
  ${mq({
    marginTop: ["85px", 0],
  })}
`

const MobileBox = styled(Container)`
  ${mq({
    display: "flex",
    flexDirection: ["column", "row"],
    alignItems: "center",
    paddingTop: ["60px", "45px"],
    paddingBottom: ["30px", "45px"],
  })}
`

const MobileBoxItem = styled.div`
  ${mq({
    flex: 1,
    margin: [null, "0 34px"],
    ":first-of-type": {
      marginLeft: [null, 0],
    },
    ":last-of-type": {
      marginRight: [null, 0],
    },
  })}
`

const MobileBoxTitle = styled(FeatureTitle)`
  ${mq({
    marginTop: ["15px", 0],
  })}
`

const MobileBoxText = styled(FeatureText)`
  ${mq({
    marginTop: ["15px", "25px"],
  })}
`

const RegisterBox = styled.section`
  ${mq({
    backgroundColor: "#ffffff",
    paddingTop: ["75px", "35px"],
    paddingBottom: ["75px", "65px"],
  })}
`

const RegisterBoxItem = styled.div`
  ${mq({
    flex: 1,
    marginRight: [null, "128px"],
  })}
`

const AppImage = styled(Img)`
  ${mq({ width: ["288px", null, "338px", "565px"] })}
`

const PigImage = styled.img`
  ${mq({
    width: ["82px", "116px"],
  })}
`

const CoffeeImage = styled.img`
  ${mq({
    width: "114px",
  })}
`

export default function HomePage() {
  const data = useStaticQuery(graphql`
    query {
      bgOfficeImage: file(relativePath: { eq: "bg-office.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1067) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      profitabilityImage: file(
        relativePath: { eq: "feature-lucratividade.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 714) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reportImage: file(relativePath: { eq: "feature-relatorio.png" }) {
        childImageSharp {
          fluid(maxWidth: 714) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      toolsImage: file(relativePath: { eq: "tools.png" }) {
        childImageSharp {
          fixed(width: 217) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      appMobileImage: file(relativePath: { eq: "app-mobile-labor.png" }) {
        childImageSharp {
          fluid(maxWidth: 565) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [deviceId, setDeviceId] = useState(null)

  useEffect(() => {
    setDeviceId(
      typeof window !== 'undefined' && window.amplitude && typeof window.amplitude.getInstance().options !== 'undefined' ? 
      '?amp_device_id='.concat(window.amplitude.getInstance().options.deviceId) : 
      '?'
    )
  }, [])
  return (
    <Page>
      <Container>
        <Heading>
          <Background
            tag="div"
            fluid={data.bgOfficeImage.childImageSharp.fluid}
          />
          <HeadingBox>
            <PageTitle>Controle de horas fácil e intuitivo</PageTitle>
            <HeadingText>
              Controle onde você e seu time investem o tempo e entenda como ser
              mais produtivo.
              {/* <br /> */}
              {/* <strong>Teste o Labor por 30 dias gratuitamente!</strong> */}
            </HeadingText>
            <SunsetBox>
              O Labor encerrará suas atividades no dia <u>30/08/2024</u>. Até lá novas contas não serão criadas.
            </SunsetBox>
            {/* <HeadingButton href={`${process.env.WEB_APP_URL}/cadastrar${deviceId}`}>
              Comece Gratuitamente
            </HeadingButton> */}
          </HeadingBox>
        </Heading>
      </Container>
      <FeatureList>
        <Container>
          <Feature>
            <HideBeforeMedium style={{ flex: 1 }}>
              <FeatureImage fluid={data.reportImage.childImageSharp.fluid} />
            </HideBeforeMedium>
            <FeatureBox>
              <HideBeforeLarge>
                <img
                  alt="Ilustração de um paleta de cores"
                  src={colorPaletteImg}
                  style={{ alignSelf: "baseline", width: "109px" }}
                />
              </HideBeforeLarge>
              <FeatureTitle>Controle suas horas em cada projeto</FeatureTitle>
              <ShowBeforeMedium>
                <FeatureImage fluid={data.reportImage.childImageSharp.fluid} />
              </ShowBeforeMedium>
              <FeatureText>
                Com o Labor você{" "}
                <strong>gerencia seus projetos e tarefas</strong> sabendo quanto
                tempo está investindo em cada um, melhorando a produtividade do
                seu time.
              </FeatureText>
            </FeatureBox>
          </Feature>

          <Feature>
            <FeatureBox left>
              <HideBeforeLarge>
                <img
                  alt="Ilustração de um capacete de pedreiro"
                  src={helmetImg}
                  style={{ alignSelf: "baseline", width: "109px" }}
                />
              </HideBeforeLarge>
              <FeatureTitle>
                Acompanhe a lucratividade de projetos para clientes
              </FeatureTitle>
              <ShowBeforeMedium>
                <FeatureImage
                  fluid={data.profitabilityImage.childImageSharp.fluid}
                />
              </ShowBeforeMedium>
              <FeatureText>
                O Labor te ajuda a entender se{" "}
                <strong>um projeto para um cliente está sendo lucrativo</strong>{" "}
                ou não. Fazemos isso com base nas horas previstas e já gastas.
              </FeatureText>
            </FeatureBox>
            <HideBeforeMedium style={{ flex: 1 }}>
              <FeatureImage
                right
                fluid={data.profitabilityImage.childImageSharp.fluid}
              />
            </HideBeforeMedium>
          </Feature>
        </Container>
      </FeatureList>
      <SocialBox>
        <SocialBoxContainer>
          <SocialBoxItem>
            <Quotes />
          </SocialBoxItem>
          <SocialBoxItem>
            <ToolsImage fixed={data.toolsImage.childImageSharp.fixed} />
            <FeatureTitle style={{ marginTop: "35px" }}>
              Descubra como você investe seu tempo de trabalho
            </FeatureTitle>
            <FeatureText style={{ marginTop: "15px" }}>
              Saiba o tempo exato de cada tarefa e quem a executou. Assim você
              terá <strong>métricas</strong> para analisar tanto a{" "}
              <strong>saúde financeira</strong> do negócio quanto a sua{" "}
              <strong>produtividade</strong>.
            </FeatureText>
          </SocialBoxItem>
        </SocialBoxContainer>
      </SocialBox>
      
      <RegisterBox>
        <Container
          style={{
            display: "flex",
            flexDirection: ["column", "row"],
            alignItems: "center",
          }}
        >
          <RegisterBoxItem>
            {/* <PageTitleParagraph
              style={{
                fontFamily: "Bitter, serif",
                fontSize: "28px",
                fontWeight: "normal",
                lineHeight: 1.29,
                color: "#5f5f5f",
                maxWidth: "300px",
              }}
            >
              Teste gratuitamente o Labor por 30 dias!
            </PageTitleParagraph> */}
            {/* <Button
              as="a"
              href={`localhost:3001/cadastrar${deviceId}`}
              style={{ marginTop: "35px" }}
            >
              Comece Gratuitamente
            </Button> */}
          </RegisterBoxItem>
          <HideBeforeMedium style={{ width: "216px" }}>
            <PigImage src={pigImg} />
            <Text style={{ marginTop: "20px", fontSize: "15px" }}>
              Depois do período de teste, assine um dos nossos planos.
            </Text>
            <Link
              to="/planos"
              style={{
                display: "inline-block",
                color: "#ff3c58",
                fontSize: "15px",
                fontWeight: 600,
                marginTop: "15px",
              }}
            >
              Conheça os Planos
            </Link>
          </HideBeforeMedium>
          <HideBeforeLarge
            style={{
              width: "203px",
              alignSelf: "flex-end",
              marginLeft: "54px",
            }}
          >
            <CoffeeImage src={coffeeImg} />
            <Text style={{ marginTop: "20px", fontSize: "15px" }}>
              Quer saber como o Labor funciona antes de começar?
            </Text>
            <Link
              to="/como-usar"
              style={{
                display: "inline-block",
                color: "#ff3c58",
                fontSize: "15px",
                fontWeight: 600,
                marginTop: "15px",
              }}
            >
              Saiba Como Usar
            </Link>
          </HideBeforeLarge>
        </Container>
      </RegisterBox>
    </Page>
  )
}
